import React, { useContext } from "react";
import useService from "../../../hooks/useService";
import { OrderCartDetail } from "../../../context/OrderCart";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/Auth";
import { message } from "antd";

function PricingSec() {
  const data = useService();

  const navigate = useNavigate();

  const { setOrderCart } = useContext(OrderCartDetail);
  const { userInfo } = useContext(AuthContext);

  const handlePlaceOrder = (
    serviceName,
    packageType,
    packageUrl,
    price,
    duration,
    cta
  ) => {
    if (
      userInfo?.userType === "viewer" ||
      userInfo?.userType === "manager" ||
      userInfo?.userType === "tMember"
    ) {
      message.error("Your account is restricted from placing orders.");
    } else {
      if (cta === "Contact Us") {
        navigate("/contact-support");
      } else {
        setOrderCart({
          service: serviceName,
          package: packageType,
          packageUrl,
          price,
          duration,
        });
        navigate("/place-order-quantity");
      }
    }
  };

  return (
    <>
      <section
        className="pricing-sec position-relative py-4 py-sm-5 mb-md-4 page-n-m"
        data-bs-spy="scroll"
        data-bs-target="#price-scroll"
        id="place-order"
      >
        <div className="row justify-content-center g-0">
          <div className="col-12 col-lg-11">
            <div className="row">
              {data?.attributes?.features?.map((fnp, i) => (
                <div className="col-12 col-md-4 mt-lg-4 mt-2" key={i}>
                  <div className="card rounded-3 border h-100">
                    {/* <span className="d-block small text-uppercase text-dark fw-bold mb-4">
                      Features included :
                    </span> */}
                    <ul className="list-unstyled mb-3">
                      {fnp?.feature?.map((f, i) => (
                        <li
                          className="d-flex align-items-center small gap-2 mb-3"
                          key={i}
                        >
                          <span className="d-inline-flex">
                            <svg
                              width={20}
                              height={20}
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M7.5 10L9.16667 11.6667L12.5 8.33333M17.5 10C17.5 14.1421 14.1421 17.5 10 17.5C5.85786 17.5 2.5 14.1421 2.5 10C2.5 5.85786 5.85786 2.5 10 2.5C14.1421 2.5 17.5 5.85786 17.5 10Z"
                                strokeWidth={2}
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                data-path="0.0.0.0.1.0.1.0.2.0.0.0"
                              />
                            </svg>
                          </span>
                          {f?.name}
                        </li>
                      ))}
                    </ul>
                    <div className="row align-items-start justify-content-between border-top py-4 mt-auto">
                      <div className="col-12 col-sm mb-4 mb-sm-0">
                        <h6 className="small text-uppercase fw-normal text-secondary mb-2">
                          {fnp?.packageType}
                        </h6>
                        <p className="fw-semibold mb-0">
                          {fnp?.packageSpeciality}
                        </p>
                      </div>
                      <div className="col-12 col-sm-auto">
                        <span className="fs-4 fw-bold lh-sm">
                          ${fnp?.price}
                        </span>
                        <span className="fs-8 fw-bold lh-sm">
                          /{fnp?.duration}
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="w-100 btn oms-btn position-relative overflow-hidden shadow"
                      onClick={() =>
                        handlePlaceOrder(
                          data?.attributes?.name,
                          fnp?.packageType,
                          fnp?.packageUrl,
                          fnp?.price,
                          fnp?.duration,
                          fnp?.cta
                        )
                      }
                    >
                      {fnp?.cta}
                    </button>
                  </div>
                  {/*.card*/}
                </div>
              ))}
              {(data?.attributes?.features?.length === 2 ||
                data?.attributes?.features?.length === 5 ||
                data?.attributes?.features?.length === 8) && (
                <div className="col-12 col-md-4 mt-lg-4 mt-2">
                  <div className="card rounded-3 border h-100 text-center justify-content-center">
                    <span className="h5 d-block text-uppercase text-dark fw-bold mb-4">
                      Premium
                    </span>
                    <p className="fw-normal mb-3">
                      Do you have any special requirements?
                    </p>
                    <h6 className="text-uppercase fw-semibold text-secondary mb-5">
                      Contact Us to Get a Quote
                    </h6>
                    <Link to="/contact-support">
                      <button
                        type="button"
                        className="w-100 btn oms-btn-outline position-relative overflow-hidden"
                      >
                        Contact Us
                      </button>
                    </Link>
                  </div>
                </div>
              )}
              {/*.col-grid*/}
            </div>
            {/*.row*/}

            <div className="row">
              <div className="col-12">
                <div className="text-center bg-white rounded-3 border mt-4 shadow-sm py-3">
                  <p className="m-0 secondary-text fw-semibold">
                    <span className="icon d-inline-block mx-1">
                      <i className="bi bi-lightning-charge" />
                    </span>
                    Express Delivery and a 10% Discount Option are Available at
                    Checkout.
                  </p>
                </div>
              </div>
              {/*.col-grid*/}
            </div>
            {/*.row*/}
          </div>
          {/*.col-grid*/}
        </div>
        {/*.row*/}
      </section>
    </>
  );
}

export default PricingSec;
